html {
	height: 100%;
	width: 100%;
}
body {
	margin: 0;
	font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
		"Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
	width: 100%;
	overflow: hidden;
}

#root {
	width: 100%;
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

wy-messenger {
	/* override inherited font */
	--wy-font-family: "Nunito Sans", sans-serif;
}

.wy-message-me {
	--wy-component-background-color: #52bd8f;
}
